// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '../packs/application.js'

// import '../stylesheets/application.css'
// import '../stylesheets/application.css'

import '../stylesheets/bulma.min.css';
import '../stylesheets/helpers.css';
import '../stylesheets/loader.css';
import '../stylesheets/custom.css';

// require("@rails/ujs").start()
import Rails from "@rails/ujs"
Rails.start()
// require("turbolinks").start()
// require("jquery")
// import jQuery from "jquery"
// require("@rails/activestorage").start()
import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()

// require("channels")
// const channels = import.meta.glob('../channels/*.js')
import "../channels/consumer.js"
import "../channels/rpi_channel.js"

// require("packs/bulma-extensions.min")
import "../packs/bulma-extensions.min"
// require("packs/custom")
import "../packs/custom"
